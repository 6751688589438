<template>
<div class="blocklog-box page-container">
	<p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
	 可以创建待办事项进行合理安排客户跟进记录
	 </div>
	 <div class="search-form">
	     <el-form ref="form" :inline="true"  :model="search_key" prop="">
	         <el-form-item label="">
	             <el-select v-model="search_key.type" placeholder="请选择">
	                 <el-option label="请选择" value=""></el-option>
	                 <el-option label="个人创建" :value="0"></el-option>
	                 <el-option label="任务中心" :value="1"></el-option>
	                 <el-option label="分配客户" :value="2"></el-option>
					<el-option label="申请权限" :value="2"></el-option>
	             </el-select>
	         </el-form-item>
	           <el-form-item label="时间范围">
	                  <el-date-picker
	                     v-model="search_key.timeSpan"
	                     type="datetimerange"
	                     range-separator="至"
	                     start-placeholder="开始日期"
	                     end-placeholder="结束日期"
	                     value-format="yyyy-MM-dd HH:mm:ss">
	                 </el-date-picker>
	              </el-form-item>
	         <el-button type="primary" @click="getData()">查询</el-button>
	     </el-form>
	 </div>
	  <div style="margin-bottom:10px;">
	     <el-button size="small" type="" @click="handleAdd()">添加</el-button>
	  </div>
	  <el-table
	     :data="tableData" 
	     style="width: 100%">
	      <el-table-column
	            prop="id"
	            label="ID">
	     </el-table-column>
	     <el-table-column
	         prop="title"
	         label="标题">
	     </el-table-column>
	     <el-table-column
	         prop="type_text"
	         label="类型">
	     </el-table-column>
	     <el-table-column
	         prop="status"
	         label="状态">
	         <template slot-scope="scope">
	             <span v-if="scope.row.status < 1" style="color:#909399;">未读</span>
	             <span v-if="scope.row.status == 1" style="color:#409EFF;">已读</span>
	             <span v-if="scope.row.status == 2" style="color:#67C23A;">完成</span>
	         </template>
	     </el-table-column>
	     <el-table-column
	         prop="create_admin_name"
	         label="创建者">
	     </el-table-column>
	      <el-table-column
	         prop="create_time"
	         label="创建时间">
	     </el-table-column>
	      <el-table-column
	         fixed="right"
	         width="240px"
	         label="操作">
	         <template slot-scope="scope">
	            <el-button v-if="scope.row.status != 2"   size="small" type="primary" plain  @click="handleStatus(scope.row)">完成</el-button>
	            <el-button V-if="scope.row.current_admin_id == scope.row.create_admin_id"   size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
	         </template>
	     </el-table-column>  
	 </el-table> 
	<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
	<AddVue ref="childDialog"></AddVue>
</div>
</template>

<script>
import AddVue from './Add.vue'
export default {
	inject:['reload'],
	components:{
	    AddVue,
	},
    data() {
        return {
			tableData:[],
            page:1,
            limit:10,
            total:0,
            dialogAddVisible:false,
            search_key:{
                type:'',
                timeSpan:''
            }
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){
		    var arr = []
		    arr['page'] = this.page
		    arr['limit'] = this.limit
		    arr['type'] = this.search_key.type
		    arr['timeSpan'] = this.search_key.timeSpan
		    var params = {...arr}
		    this.$api.post('/Backlog/index',params,res=>{
		        if(res.code == 200){
		            this.tableData = res.data.list
		            this.total =  res.data.count
		        }
		    })
		},
		handleAdd(){
		    this.$refs.childDialog.handleAdd()
		},
		handleDel(row){        //删除待办事项
		     this.$alert("确定删除该待办吗？", "删除提示", {
		        // confirmButtonText:'确定',
		        showConfirmButton: true,
		        showCancelButton: true,
		        callback: action => {
		            if (action == "confirm") {
		                this.$api.delete("/Backlog/del", {"id": row.id }, res => {
		                    if (res.code == 200) {
		                        this.$message.success(res.msg);
		                        this.reload();
		                    }
		                });
		            }
		            else if (action == "cancel") {
		                this.reload();
		            }
		        }
		    });
		},
		handleStatus(row){     //更改状态
		    this.$api.put('Backlog/updateStatus',{id:row.id,status:2},res=>{
		        if(res.code ==200){
		             this.$message.success(res.msg);
		             this.reload()
		        }else{
		             this.$message.error(res.msg);
		        }
		    })
		},
		getCurrPage(val){   //获取当前页
		    this.page =  val
		    console.log(this.page)
		    this.getData()
		},
		getPageSize(val){   //获取每页条数
		    this.limit =  val
		    this.getData()
		},
    },
}
</script>
<style scoped>

</style>