<template>
<div>
	<!-- 添加待办事项 -->
	<el-dialog title="添加待办事项" :visible.sync="dialogAddVisible" :close-on-click-modal="false"  width="35%">
	     <el-form ref='form' :model="form" prop="" label-width="150px">
	        <el-form-item label="标题" prop="title">
	            <el-input  v-model="form.title"></el-input>
	        </el-form-item>
	         <el-form-item label="待办内容" prop="content">
	            <el-input type="textarea"  rows="7"  v-model="form.content"></el-input>
	        </el-form-item>
	     </el-form>
	     <div slot="footer" class="dialog-footer">
	        <el-button @click="dialogAddVisible = false">取 消</el-button>
	        <el-button type="primary" @click="confirm()">确 定</el-button>
	    </div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			 dialogAddVisible:false,
			 typeList:[],
			 form:{
			     id:'',
			     title:'',
			     content:'',
			     admin_id:'',
			     type:''
			 },
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		handleAdd(){
		    this.$api.get('/Backlog/add',null,res=>{
		        if(res.code == 200){
		            this.typeList =  res.data.type_list
		            this.dialogAddVisible = true;
		        }
		    })
		},

		confirm(){
		    this.$api.post('/Backlog/add',this.form,res=>{
		        if(res.code == 200){
		            this.$message.success(res.msg);
		            this.dialogAddVisible = false;
		            this.$refs["form"].resetFields();
		            this.reload();
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
    },
}
</script>
<style scoped>

</style>